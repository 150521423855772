import styled from "styled-components";

const IconButtonLabel = styled.span`
    font-family: KareliaWeb-Regular, Montserrat, sans-serif;
    font-size: 0.65rem;
    color: ${props => props.$on=="true"? "#A8DC00" : "grey"};
    @media (max-width: 770px) {
        font-size: 0.6rem;
    }
`

export default IconButtonLabel;