import React from "react";
import styled, { keyframes }from "styled-components";

const appear = keyframes`
    from {width: 0%;}
    to {width: 100%;}
`

const mutePulse = keyframes`
0% {box-shadow: rgb(34, 39, 49) 0px 0px 0px 0px;}
70% {box-shadow: rgba(34, 39, 49, 0) 0px 0px 0px 10px;}
100% {box-shadow: rgba(34, 39, 49, 0) 0px 0px 0px 0px;}
`

const ZoneContainer = styled.div`
    display: flex;
    align-items: center;
    border-width: 4px;
    border-style: solid;
    border-color: rgb(34, 39, 49);
    width: 100%;
    max-width: 1100px;
    min-height: 70px; 
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    @media (max-width: 770px){
        min-height: 70px;
    }
`



const ZoneInnerContainer = styled.div`
    display: grid; 
    grid-template-columns: 20% auto 16% 2.5fr auto auto;

    justify-items: center; 
    align-items: center; 
    gap: 10px;
    padding: 10px;
    hight: 100%;
    width: 100%;
    background-color: #0C101C;

    @media (max-width: 770px) {
        grid-template-columns: 1fr auto 40px 40px;
    }
`



const LabelContainer = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    justify-self: start; 
    position: relative;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100%); 
    font-size: 1.2rem;
    font-family: KareliaWeb-Regular, Montserrat, sans-serif;
`


const VibeSliderContainer = styled.div`
    grid-column-start: ${props => props.$columnStart? props.$columnStart : 1};
    grid-column-end: ${props => props.$columnEnd? props.$columnEnd : 2};

    @media (max-width: 770px) {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 1;
        ${props => (props.$vibe=== "true")?`
            grid-column-end: 4;
        `:`
            grid-column-end: 5;
        `}
    }

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #0C101C;
`

export {
    ZoneContainer,
    ZoneInnerContainer,
    LabelContainer,

    VibeSliderContainer,
    appear,
    mutePulse,
} 