import React from "react";
import styled from "styled-components";
import IconButtonContainer from "./IconButtonContainer";
import IconButtonLabel from "./IconButtonLabel";
import {ReactComponent as Svg} from '../../icons/Audience.svg';
import { appear } from "../UI/PanelContainers";

const Icon = styled(Svg)`
    height: 1.25rem;
    width: 1.25rem;
    path {
        stroke: ${props => (props.$togglable && (props.$on==="true"))? "#A8DC00": "grey"};
    };
    @media (max-width: 770px) {
        height: 1rem;
        width: 1rem;
    }
`

const Container = styled.button`
    display: flex;  
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: ;
    background-color: ${props => props.$togglable? "rgb(20, 25, 37)": "#0C101C"};
    border: none;
    @media (max-width: 770px) {
        height: 40px;
        width: 40px;
    }
`

const PeopleButton = (props) => {

    return (
        <Container onClick={props.onClick} $togglable={props.$togglable}>
            <Icon $togglable={props.$togglable} $on={props.$on}></Icon>
            <IconButtonLabel $on={props.$on} >People</IconButtonLabel>
        </Container>
    )
}

export default PeopleButton;