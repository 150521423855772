import React from "react";
import styled from "styled-components";
import IconButtonLabel from "./IconButtonLabel";
import {ReactComponent as Svg} from '../../icons/Speakers.svg';

const Icon = styled(Svg)`
    height: 1.25rem;
    width: 1.25rem;
    path {
        stroke: grey;
    };
    @media (max-width: 770px) {
        height: 1rem;
        width: 1rem;
    } 
`

const Container = styled.button`
    display: flex;  
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #0C101C;
    border: none;
    margin: 10px 0;
    @media (max-width: 770px) {
        height: 40px;
        width: 40px;
    }
`

const MusicButton = (props) => {

    return (
        <Container onClick={props.onClick}>
            <Icon></Icon>
            <IconButtonLabel>Music</IconButtonLabel>
        </Container>
    )
}

export default MusicButton;