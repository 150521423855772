import styled from "styled-components";

const H1 = styled.h1`
    margin-top: 10px;
    margin-bottom: 0px;
    font-family: KareliaWeb-Regular, Montserrat, sans-serif;
`

const H2 = styled.h2`
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: KareliaWeb-Regular, Montserrat, sans-serif;
`

const H3 = styled.h3`
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: KareliaWeb-Regular, Montserrat, sans-serif;
`

const H4 = styled.h4`
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: KareliaWeb-Regular, Montserrat, sans-serif;
`

const H5 = styled.h5`
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: KareliaWeb-Regular, Montserrat, sans-serif;
`

const H6 = styled.h6`
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: KareliaWeb-Regular, Montserrat, sans-serif;
`

const ZoneLabel = styled.span`
    font-size: 1.2rem;
    font-family: KareliaWeb-Medium, Montserrat, sans-serif;
    color: ${props => props.color? props.color : "#FFFFFF"};
    @media (max-width: 770px){
        font-size: 0.9rem;   
    }
`

export  { H1, H2, H3, H4, H5, H6, ZoneLabel}
