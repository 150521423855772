import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { appear, keyframes } from "./UI/PanelContainers";
import PeopleButton from "./Buttons/PeopleButton";
import MusicButton from "./Buttons/MusicButton";
import {ReactComponent as Svg} from '../icons/Handle.svg';

const HandleIcon = styled(Svg)`
    position: absolute;
    left: ${props => props.position? `calc(${props.position}% + ${props.pixels}px)`: 50};
    height: 0.75rem;
    width: 0.75rem;
    pointer-events: none;
    circle {
        fill: black;
        fill-opacity: 0.4;
    }; 
    rect {
      fill: #A8DC00;
    }
    z-index: 10;
`
//${props => props.position? `calc(${props.position}% + ${props.pixels}px)`: 50};

const SliderContainer = styled.div`
    position: relative; 
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 100%;
    height: 50px;
    background-color: #0C101C;
    @media (max-width: 770px){
        height: 40px; 
    }
`

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 100%;
    height: 50px;
    background-color: #0C101C; 
    @media (max-width: 770px){
        height: 40px; 
    }

    animation: ${appear} 1s;
    animation-fill-mode: forwards;
`

const Slider = styled.input`
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px;
    background: rgb(114, 117, 125);
    z-index: 1;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        border: none;
        height: 30px;
        max-width: 30px;
        background-color: #A8DC00;
        border-radius: 50%;
        
    }
`

const Thumb = styled.div`
    position: absolute;
    left: ${props => props.position? `calc(${props.position}% + ${props.pixels-9}px)`: 50};
    pointer-events: none;
    border: none;
    height: 30px;
    width: 30px;
    background-color: #A8DC00;
    border-radius: 50%;
    z-index: 9;
`

const Tick = styled.div`
    position: absolute;
    left: ${props => props.position? `calc(${props.position}% + ${props.pixels}px)`: 50};
    pointer-events: none;
    height: 2px;
    width: 2px;
    background-color: #0C101C;
    border-style: solid;
    border-color: rgb(114, 117, 125);
    border-width: 2px;
    border-radius: 4px;
    z-index: 2;
`

const CoverEnd = styled.div`
    position: absolute; 
    height: 50px;
    width: ${props => props.wpr? `calc(${props.wpr}% + ${props.wpi}px)`: 50};
    background-color: #0C101C;
    left: ${props => props.left? props.left :`calc(${props.lpr}% + ${props.lpi}px)`}; 
    z-index: 7;
    
    @media (max-width: 770px) {
        max-height: 40px;
    } 
`

const VibeSlider = (props) => {

    const [sliderValue, setSliderValue] = useState(props.vibe);

    const [handlePosition, setHandlePosition] = useState(0);
    const [pixels, setPixels] = useState(0);

    const tickPositions = [
        [getTickPersentage(-2), getTickPixels(-2)],
        [getTickPersentage(-1), getTickPixels(-1)],
        [getTickPersentage(0), getTickPixels(0)],
        [getTickPersentage(1), getTickPixels(1)],
        [getTickPersentage(2), getTickPixels(2)]
    ]

    useEffect(() => {
        setSliderValue(props.vibe)
        updateHandlePosition(props.vibe);
    }, [props.vibe]);

    function updateHandlePosition(value){
        var handlePersent = value*25 + 50;
        if (handlePersent<= 0 ) handlePersent=0.0001;
        setHandlePosition(handlePersent);
        var min = -6;
        var max = -23;
        var pix = value*(max-min)/2 + min;
        setPixels(pix);
      }

    function getTickPersentage(value) {
        var tickPersent = value*25 + 50;
        if (tickPersent<= 0 ) tickPersent=0.0001;
        return(tickPersent);
    }

    function getTickPixels(value) {
        var min = -3;
        var max = -20;
        var pix = value*(max-min)/2 + min;
        return pix;
    }
    
    const handleChange = (value) => {
        const newVibe = parseInt(value);
        setSliderValue(newVibe);
        props.setVibe(newVibe);
        updateHandlePosition(newVibe);
    }

    return (
        <Container>
            <PeopleButton $animate={"true"} onClick={() => {props.setVibe(props.vibe-1)}}/>
            <SliderContainer>
                <CoverEnd
                    left = {"0px"} 
                    wpr={tickPositions[0][0]}
                    wpi={tickPositions[0][1]}
                />
                <CoverEnd
                    wpr={tickPositions[0][0]}
                    wpi={tickPositions[0][1]}
                    lpr={tickPositions[4][0]}
                    lpi={tickPositions[4][1]+6}
                />
                <Slider
                    type={"range"}
                    min = {-2}
                    max = {2}
                    value = {sliderValue}
                    onChange={(event) => {handleChange(event.target.value)}}
                    onMouseUp={(event) => {handleChange(event.target.value)}}
                    onTouchEnd={(event) => {handleChange(event.target.value)}}
                />
                <Tick position={tickPositions[0][0]} pixels={tickPositions[0][1]}></Tick>
                <Tick position={tickPositions[1][0]} pixels={tickPositions[1][1]}></Tick>
                <Tick position={tickPositions[2][0]} pixels={tickPositions[2][1]}></Tick>
                <Tick position={tickPositions[3][0]} pixels={tickPositions[3][1]}></Tick>
                <Tick position={tickPositions[4][0]} pixels={tickPositions[4][1]}></Tick>
                <Thumb position={handlePosition} pixels={pixels}></Thumb>
                <HandleIcon position={handlePosition} pixels={pixels}></HandleIcon>
            </SliderContainer>
            <MusicButton onClick={() => {props.setVibe(props.vibe+1)}} />
        </Container>
    )
}

export default VibeSlider;