import React from "react";
import styled from "styled-components";
import IconButtonContainer from "./IconButtonContainer";
import IconButtonLabel from "./IconButtonLabel";
import {ReactComponent as SensorSvg} from '../../icons/Source.svg';

const SourceIcon = styled(SensorSvg)`
    height: 1.25rem;
    width: 1.25rem;

    path {
        stroke: #A8DC00;
    }; 
    @media (max-width: 770px) {
        height: 1.1rem;
        width: 1.1rem;
    } 

`

const SourceButtonContainer = styled.button`
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center; 
    width: 50px;
    align-self: stretch; 
    max-height: 70px;
    background-color: rgb(34, 39, 49);
    border: solid;
    border-color: rgb(34, 39, 49);
    border-right-width: 3px;
    border-left-width: 0px;
    border-top-width: 0px;
    border-bottom-width: 0px;
    margin: 0px;
    padding: 0px; 
    @media (max-width: 770px) {
        width: 40px;
        max-height: 120px;
    } 
`

const SourceButton = (props) => {

    if(props.stale) {
        return (
            <SourceButtonContainer/>
        );
    } else {
        return (
            <SourceButtonContainer style={props.addStyle} onClick={props.onClick}>
                <SourceIcon></SourceIcon>
                <IconButtonLabel $on={"true"} >Source</IconButtonLabel>
            </SourceButtonContainer>
        )
    }
}

export default SourceButton;