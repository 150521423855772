import React from 'react';
import noiseUrl from  '../audio/files/noise_p100.mp3';
import loungeUrl from  '../audio/files/lounge.mp3';
import upbeadUrl from '../audio/files/upbeat.mp3';
import popUrl from '../audio/files/pop.mp3';
import classicalUrl from '../audio/files/classic.mp3';

const context = new AudioContext();

const tracks = [
    {
        genre: 'noise',
        url: noiseUrl,
        buffer: null,
        source: null,
        volume: null,
        playing: null,
        ready: false,
    },{
        genre: 'lounge',
        url: loungeUrl,
        buffer: null,
        source: null,
        volume: null,
        playing: null,
        ready: false,
    },{
        genre: 'upbeat',
        url: upbeadUrl,
        buffer: null,
        source: null,
        volume: null,
        playing: null,
        ready: false,
    },{
        genre: 'pop',
        url: popUrl,
        buffer: null,
        source: null,
        volume: null,
        playing: null,
        ready: false,
    },{
        genre: 'classical',
        url: classicalUrl,
        buffer: null,
        source: null,
        volume: null,
        playing: null,
        ready: false,
    },
];

function tracksLoaded() {
    var ready = true;
    for (let i=0; i<tracks.length; i++) {
        if (!tracks[i].ready) {
            ready = false;
        }
    }
    return ready;
}

function loadTrack(track, volume=50) {
    var request = new XMLHttpRequest();
    request.open('GET', track.url, true);
    request.responseType = 'arraybuffer';
    request.onload = () => {
        context.decodeAudioData(request.response, function(buffer) {
            var source = context.createBufferSource();
            source.buffer = buffer;
            source.loop = true;
            source.connect(context.destination);
            var gainNode = context.createGain();
            source.connect(gainNode);
            gainNode.connect(context.destination);
            track.buffer = buffer;
            track.source = source;
            track.volume = gainNode;
            track.playing = false;
            track.ready = true;
            setVolume(volume, track);
        }, (e) => {console.log(e)});
    }
    request.send();
}

function loadTracks(volume=50) {
    tracks.forEach(track => {
       loadTrack(track, volume);
    });
}

function getTrack(genre) {
    const track =  tracks.find(track => track.genre === genre);
    return track;
}

function getVolume(track) {
    let v = track.volume.gain.value;
    let rv = reverseScaleVolume(v);
    return rv;
}

function playTrack(track) {     
    if (track.playing) return;
    track.source.start();
    track.playing = true;              
}

function stopTrack(track) {
    if (!track.playing) return;
    var trackCopy = {...track};
    let trackVolume = getVolume(trackCopy);
    let index = tracks.findIndex(track => track.genre === trackCopy.genre);
    track.source.stop();
    loadTrack(tracks[index], trackVolume);
}

function setRawVolume(v, track) {
    if (-1 > v || v > 1) return;
    track.volume.gain.value = v;
}

function setVolume(v, track) {
    let sv = scaleVolume(v);
    setRawVolume(sv, track);
}

function setAllTrackVolumes(v) {
    tracks.forEach(track => {
        setVolume(v, track);
    })
}

function scaleVolume (v) {
    let x_min = 0;
    let x_max = 100;
    let F_min = -1;
    let F_max = 0; //1
    let A = (F_max - F_min)/(x_max - x_min);
    let B = F_min - A*x_min;
    let sv = A*v +B
    if (sv < -1) sv = -1;
    if (sv > 1) sv = 1;
    return sv;
}

function reverseScaleVolume(v) {
    let x_min = -1;
    let x_max = 0; //1
    let F_min = 0;
    let F_max = 100;
    let A = (F_max - F_min)/(x_max - x_min);
    let B = F_min - A*x_min;
    let rv = A*v + B;
    return rv;
} 

export {
    tracksLoaded,
    loadTracks,
    getTrack,
    getVolume,
    playTrack,
    stopTrack,
    setVolume,
    setAllTrackVolumes,
}
