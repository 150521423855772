import styled from "styled-components";

const GenreContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    width: 500px; 
    height: 200px;
    padding: 20px;
    background-color: #0C101C;
    margin: 10px;
`

export default GenreContainer;
