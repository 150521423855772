import React from "react";
import styled from "styled-components";
import IconButtonContainer from "./IconButtonContainer";
import IconButtonLabel from "./IconButtonLabel";
import {ReactComponent as FrameIcon} from '../../icons/Frame.svg';

const WavedFrameIcon = styled(FrameIcon)`
    height: 1.25rem;
    width: 1.25rem;
    g{
        path {
            stroke: ${props => props.$on=="true"? "#A8DC00" : "grey"};
        };
    };   
    @media (max-width: 770px) {
        height: 1rem;
        width: 1rem;
    }
`

const WavedButtonContainer = styled(IconButtonContainer)`
    display: flex;      
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    @media (max-width: 770px) {
        grid-column-start: 4;
        grid-column-end: 5;
        height: 40px;
        width: 40px;
    }
`

const WavedButton = (props) => {

    return (
        <WavedButtonContainer style={props.addStyle} $columnStart={6} onClick={props.onClick}>
            <WavedFrameIcon $on={props.$on} />
            <IconButtonLabel $on={props.$on} >{(props.$on === "true")?"On":"Off"}</IconButtonLabel>
        </WavedButtonContainer>
    )
}

export default WavedButton;

