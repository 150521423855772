import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { appear } from './UI/PanelContainers';
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Filler, Legend } from 'chart.js';
ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Filler, Legend);

const ChartContainer = styled.div`
    position: relative;
    
    width: ${props => props.width? props.width : "500px"}; 
    height: ${props => props.height? props.height : "300px"};
    background-color: ${props => props.$bgcolor? props.$bgcolor : "#0C101C"};
    animation: ${appear} 1s;
    animation-fill-mode: forwards;

    @media (max-width: 770px) {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 4;
      grid-column-end: 5;
      height: 40px;
      width: 40px;
    }
`
// Why?? - hvorfor funker dette?
// width: 350px;
//

//  background: linear-gradient(to right, rgba(14,19,36,1), rgba(14,19,36,0.9), rgba(14,19,36,0.5), rgba(14,19,36,0));

/*
const LineContiner = styled.div`
    height: ${props => props.height? props.height : "100%"};
    width: ${props => props.width? props.width : "100%"}; 
`
*/
// width: ${props => props.width? props.width : "100%"}; 

const FadeBox = styled.div`
    background: linear-gradient(to right, rgba(12,16,28,1), rgba(12,16,28,0.9), rgba(12,16,28,0.5), rgba(12,16,28,0));
    height: 100%;
    width: 100px;
`

const LinePlot2 = (props) => {

  // Sample data
  const data = {
    labels: [...Array(props.xMax).keys()],
    datasets: [
      {
        label: 'Music',
        data: props.musicData,
        borderColor: "#A8DC00", //"#ffec9c", // "#A8DC00"
        backgroundColor: "#A8DC00", //"#ffec9c", // "#A8DC00"
        fill: false,
      },
      {
        label: 'Crowd',
        data: props.noiseData,
        borderColor: 'rgba(229,62,94,1)', //#e53e5e
        backgroundColor: 'rgba(229,62,94,0.2)', // #e53e5e
        fill: false,
      },
    ],
  };


  // Chart options
  var options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    elements: {
      point:{
          radius: 0
      }
    },
    scales: {
      y: {
        display: false,
        beginAtZero: true,
        max: props.yMax,
        grid: {
          display: true,
          color: '#141925',
        },
      },
      x: {
        display: false, 
        grid: {
          display: false, 
          color: '#141925',
        }
      }
    },
    plugins: {
      legend: {
          display: false,
          labels: {
              color: 'rgb(255, 255, 255)',
              boxHeight: 1,
          }
      }
    }
  };

  if (props.legend) {
    options.plugins.legend.display = true;
  } 

  return (
    <ChartContainer style={props.addStyle} onClick={props.onClick} height={props.height} width={props.width} $bgcolor={props.$bgcolor} >
        {props.fadeBox? <>
            <Line data={data} options={options} />
            <FadeBox></FadeBox>
        </>:<>
          <Line data={data} options={options} />
        </>}
    </ChartContainer>
  )
};

export default LinePlot2;