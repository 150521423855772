import React, { useEffect } from "react";
import styled from "styled-components";
import { ZoneLabel } from "../Text";

const VolumePercentageContainer = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    height: 50px;
    width: 50px;
    @media (max-width: 770px){
      height: 40px; 
      width: 40px;   
    }

    
    
`

const ZoneText = styled.span`
    font-size: ${props => props.fontSize? props.fontSize : "1rem"};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: KareliaWeb-Medium, Montserrat, sans-serif;
    color: ${props => props.color? props.color : "#FFFFFF"};
    @media (max-width: 770px){
        font-size: 0.9rem;
    }
`



const VolumePercentage = (props) => {

    useEffect(() => {

    }, [props.volume, props.wavedOn])

    if (props.wavedOn) {
        return (
            <VolumePercentageContainer style={props.addStyle} onClick={props.onClick}>
                <ZoneText color={"#A8DC00"}>{Math.round(props.volume)}%</ZoneText>
            </VolumePercentageContainer>
        )
    } else {
        return (
            <VolumePercentageContainer onClick={props.onClick}>
                <ZoneText>{Math.round(props.volume)}%</ZoneText>
            </VolumePercentageContainer>
        );   
    }    
}

export default VolumePercentage;