import React from "react";
import Overlay from "../containers/Overlay";
import GenreSelector from "./GenreSelector";


const Modal = (props) => {


    const handleParentClick = () => {
        props.setShowModal();
    }

    const handleChildClick = (event, genre) => {
        event.stopPropagation();
        props.updateGenre(genre);
    }

    return (
        <Overlay $show={props.$show} onClick={handleParentClick}>
            <GenreSelector
                updateGenre={handleChildClick}
                genre = {props.genre}
            />
        </Overlay>
    )
}

export default Modal;