import styled from "styled-components";

const AudioPlayerContainer = styled.div`
    position: relative;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0C101C;
    color: white;
    padding: 10px;
    
`

//NOTE: 85 vh works in demo site! (originaly 100vh)

export default AudioPlayerContainer;
