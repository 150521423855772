import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Overlay from "../containers/Overlay";
import Modal from "./Modal";
import { H3, H4, H5,  } from "./Text";
import GenreSelector from "./GenreSelector";
import GenreContainer from "../containers/GenreContainer";

const Background = styled.div`
    position: relative; 
    left: ${props => props.left? props.left: "0"};
    top: ${props => props.top? props.top: "-20%"};
    width: 400px;
    max-width: 80%;
    background-color: rgba(14, 19, 36);
    boxShadow: 0px 0px 10px rgba(14, 19, 36, 0.9);
    margin: 20px;
    @media (max-width: 770px) {
        left: 0px;
        top: -25%;
    } 
`

const SourceModalWrapper = styled.div`
    position: absolute;
    display: ${ props => props.$show===("true")? "flex" : "none"};
    justify-content: ${ props => props.$show===("true")? "center" : "none"};
    align-items: ${ props => props.$show===("true")? "center" : "none"};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @media (max-width: 770px) {
        left: 30px;
        transform: scale(0.8);
        
    } 
`

const AudioPlayerDemoModal = (props) => {

    const [localgenre, setlocalGenre] = useState('lounge');

    function handleParentClick() {
        props.handleModalClick(false);
        props.setShowSources(false);
    }

    function updateGenre(event, genre) {
        event.stopPropagation();
        setlocalGenre(genre);
    }

    return(
        <>
            <Overlay color={"rgba(14, 19, 36, 0.8)"} $show={(props.demo==="SOURCE").toString()} onClick={handleParentClick}>
                <Background left={"-25%"} top={"-25%"}>
                    <H3>Source</H3>
                    Use the source button to change between your sources.<br></br>
                    Typical sources include, the main music player, a DJ source, an iPad or similar.<br></br>
                    Try it!
                </Background>
                <SourceModalWrapper
                    $show={(props.showSources).toString()}
                    setShowModal={props.setShowSources}
                >
                    <GenreSelector
                        updateGenre={updateGenre}
                        genre = {localgenre}
                    />
                </SourceModalWrapper>
            </Overlay>

            <Overlay color={"rgba(14, 19, 36, 0.8)"} $show={(props.demo==="ZONE").toString()} onClick={handleParentClick}>
                <Background left={"0%"} top={"-25%"}>
                    <H3>Zone Bar</H3>
                    Each zone in your venue is represented as a horisontal bar.<br></br>
                    Frome here you can controle everyting audio-related such as 
                    changeing the source, vibe the music to the foreground or background, mute or turn automation on or off. 
                </Background>
            </Overlay>

            <Overlay color={"rgba(14, 19, 36, 0.8)"} $show={(props.demo==="PERCENTAGE").toString()} onClick={handleParentClick}>
                <Background left={"-10%"} top={"-20%"}>
                    <H3>Current volume level</H3>
                    Shows you the current volume level for the zone.
                </Background>
            </Overlay> 

            <Overlay color={"rgba(14, 19, 36, 0.8)"} $show={(props.demo==="PLOT").toString()} onClick={handleParentClick}>
                <Background left={"-8%"} top={"-20%"}>
                    <H3>Live Plot</H3>
                    Press it to see the activity in your venue the past 24 hours.
                </Background>
            </Overlay>

            <Overlay color={"rgba(14, 19, 36, 0.8)"} $show={(props.demo==="VIBE").toString()} onClick={handleParentClick}>
                <Background left={"13%"} top={"-25%"}>
                    <H3>Vibe Slider</H3>
                    Use the Vibe Slider to set the music more in the foreground to get a more vibrant atmorphere,
                    or have it more in the background to create a more quiet and calm atmosphere.
                    <br></br><br></br>
                    While Waved Automation is on, the music will still follow the energy and dynamics of your guests,
                    while you controle the balance between the music and the people with the Vibe Slider.<br></br>
                    Try it!
                </Background>
            </Overlay>

            <Overlay color={"rgba(14, 19, 36, 0.8)"} $show={(props.demo==="MUTE").toString()} onClick={handleParentClick}>
                <Background left={"20%"} top={"-20%"}>
                    <H3>Mute or unmute</H3>
                    You can mute and unmute each zone individualy any time.
                </Background>
            </Overlay>

            <Overlay color={"rgba(14, 19, 36, 0.8)"} $show={(props.demo==="WAVED").toString()} onClick={handleParentClick}>
                <Background left={"25%"} top={"-20%"}>    
                    <H3>Waved Automation On/Off</H3>
                        Turn Waved Automation On or Off depending on your needs. <br></br>
                        If turned off you can use a normal volume slider to adjust the music volume manually.
                </Background>
            </Overlay>
        </>
    );
}

export default AudioPlayerDemoModal;