import styled from "styled-components";

const DemoButton = styled.button`
    background-color: #A8DC00;
    padding: 3px 16px;
    height: 30px;
    border-radius: 15px;
    font-size: 0.8rem;
    border: none;
    color: black;
    font-family: KareliaWeb-Medium, Montserrat, sans-serif;
`

export default DemoButton;