import styled from 'styled-components';
import wavedLogo from '../images/Waved.png';

const StyledLogo = styled.img`
    width: 180px;
    margin-top: 6px;
`

const Logo = () => {
    return(
        <StyledLogo src={wavedLogo}/>
    );
}

export default Logo;