import React from "react";
import styled from "styled-components";
import IconButtonContainer from "./IconButtonContainer";
import IconButtonLabel from "./IconButtonLabel";
import {ReactComponent as VolumeHigh} from '../../icons/VolumeHigh.svg';
import {ReactComponent as VolumeMute} from '../../icons/VolumeMute.svg';
import { mutePulse } from "../UI/PanelContainers";

const MuteContainer = styled(IconButtonContainer)`
    display: flex;      
    flex-direction: column; 
    align-items: center;
    justify-content: center; 
    border-radius: 2px;
    @media (max-width: 770px) {
        grid-column-start: 3;
        grid-column-end: 4;
        height: 40px;
        width: 40px;
    }
`

const MuteContainerAnimation = styled(IconButtonContainer)`
    display: flex;      
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    animation: 2s ease 0s infinite normal none ${mutePulse};
    border-radius: 2px;
    @media (max-width: 770px) {
        grid-column-start: 3;
        grid-column-end: 4;
        height: 40px;
        width: 40px;
    }
`

const VolumeHighIcon = styled(VolumeHigh)`
    height: 1.25rem;
    width: 1.25rem;
    path {
        stroke: #A8DC00;
    }; 
    @media (max-width: 770px) {
        height: 1rem;
        width: 1rem;
    }
`
const VolumeMuteIcon = styled(VolumeMute)`
    height: 1.25rem;
    width: 1.25rem;
    path {
        stroke: grey;
    };  
    @media (max-width: 770px) {
        height: 1rem;
        width: 1rem;
    }
`

const MuteButton = (props) => {

    if (props.$on==="true"){
        return(
            <MuteContainer style={props.addStyle} $columnStart={5} $rowStart={1} onClick={props.onClick}>
                <VolumeHighIcon/>
                <IconButtonLabel $on={props.$on} >{(props.$on === "true")?"On":"Muted"}</IconButtonLabel>
            </MuteContainer>
        )
    } else {
        return (
            <MuteContainerAnimation style={props.addStyle} $columnStart={5} $rowStart={1} onClick={props.onClick}>
                <VolumeMuteIcon/>
                <IconButtonLabel $on={props.$on} >{(props.$on === "true")?"On":"Muted"}</IconButtonLabel>
            </MuteContainerAnimation>
        )
    }
}

export default MuteButton;