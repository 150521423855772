import './App.css';
import React, { useState } from 'react'
import {Helmet} from 'react-helmet';
import useInterval from 'use-interval'
import AudioPlayerContainer from './containers/AudioPlayerContainer';
import AudioPlayer2 from './components/AudioPlayer';
import Logo from './components/Logo';
import LinePlot from './components/LinePlot';
import Modal from './components/Modal';
import AudioPlayerDemo from './components/AudioPlayerDemo';
import DemoButton from './components/Buttons/DemoButton';

const wavedSettings = {
  startVolume: 50,
  threshold: 3,
  fluctuation: 2.5, 
  maxAdjustment: 0.2,
  normalStep: 0.2,
  vibeValue: 10, // 6 is real
  correctingVibe: false,
  corectionStep: 0,
}

const dataSettings = {
  dataLength: 250, // 250 sems to be max due to mobile friendlyness fiddle later
  dataLengthShort: 35,
  shortUpdateInterval: null,
  shortUpdateCount: 0,
}
dataSettings.shortUpdateInterval = Math.round(dataSettings.dataLength/dataSettings.dataLengthShort);

const userActions = {
  holdingSlider: false,
  holdingSliderValue: wavedSettings.startVolume,
  holdingMusicSlider: false,
  holdingMusicSliderValue: wavedSettings.startVolume,
}

function App() {

  const [wavedOn, setWavedOn] = useState(true);
  const [vibe, setVibe] = useState(0);
  const [genre, setGenre] = useState('lounge');
  const [userSetNoise, setUserSetNoise] = useState(wavedSettings.startVolume);
  const [userSetMusic, setUserSetMusic] = useState(wavedSettings.startVolume);
  const [showModal, setShowModal] = useState(false);

  const [musicVolume, setMusicVolume] = useState(wavedSettings.startVolume);
  const [musicData, setMusicData] = useState(new Array(dataSettings.dataLength).fill(wavedSettings.startVolume));
  const [musicDataShort, setMusicDataShort] = useState(new Array(dataSettings.dataLengthShort).fill(wavedSettings.startVolume));

  const [noiseVolume, setNoiseVolume] = useState(wavedSettings.startVolume);
  const [noiseData, setNoiseData] = useState(new Array(dataSettings.dataLength).fill(wavedSettings.startVolume));
  const [noiseDataShort, setNoiseDataShort] = useState(new Array(dataSettings.dataLengthShort).fill(wavedSettings.startVolume));
  const [randomNoise, setRandomNoise] = useState(true);

  const [demoMode, setDemoMode] = useState(false);
  const [prepareForDemo, setPrepareForDemo] = useState(false);

  useInterval(() => {
    var newMusicVolume = musicVolume;
    if (wavedOn) {
      if (wavedSettings.correctingVibe) {
        wavedSettings.corectionStep++;
        wavedSettings.maxAdjustment = 2*wavedSettings.normalStep;
        if(wavedSettings.corectionStep > 3*wavedSettings.vibeValue/wavedSettings.maxAdjustment) {
          wavedSettings.correctingVibe = false;
          wavedSettings.corectionStep = 0;
          wavedSettings.maxAdjustment = wavedSettings.normalStep;
        }
      }
      var target = noiseVolume + vibe*wavedSettings.vibeValue;
      var threshold = wavedSettings.threshold;
      if (!randomNoise) threshold = 0; 
      if (musicVolume < (target - threshold)){
        newMusicVolume = musicVolume + wavedSettings.maxAdjustment
      } else if (musicVolume > (target + threshold)){
        newMusicVolume = musicVolume - wavedSettings.maxAdjustment
      }
    }
    if ((userActions.holdingMusicSliderValue != userSetMusic) && userActions.holdingMusicSlider) {
      newMusicVolume = userActions.holdingMusicSliderValue;
    }
    var randomNumber= 0; 
    if(randomNoise) randomNumber = Math.random()*2*wavedSettings.fluctuation - wavedSettings.fluctuation;
    var newNoiseVolume = noiseVolume + randomNumber;
    if ((userActions.holdingSliderValue != userSetNoise) && userActions.holdingSlider) {
      newNoiseVolume = userActions.holdingSliderValue + randomNumber;
    } else if ((Math.abs(newNoiseVolume - userSetNoise) > 4*wavedSettings.fluctuation) && wavedOn) {
        newNoiseVolume = noiseVolume;
    }
    updateData(newMusicVolume, newNoiseVolume);
  }, [60]) //125

  function updateNoise(newNoiseVolume) {
    setNoiseVolume(newNoiseVolume);
    var updatedNoiseData = [...noiseData];
    updatedNoiseData.shift();
    updatedNoiseData.push(newNoiseVolume);
    setNoiseData(updatedNoiseData);
  }

  function updateMusic(newMusicVolume) {
    setMusicVolume(newMusicVolume);
    var updatedMusicData = [...musicData];
    updatedMusicData.shift();
    updatedMusicData.push(newMusicVolume);
    setMusicData(updatedMusicData);
  }

  function updateData(newMusicVolume, newNoiseVolume) {
    if (newNoiseVolume > 99) newNoiseVolume = 99;
    else if (newNoiseVolume < 0) newNoiseVolume = 0;
    if (newMusicVolume > 99) newMusicVolume = 99;
    else if (newMusicVolume < 0) newMusicVolume = 0;
    updateNoise(newNoiseVolume);
    updateMusic(newMusicVolume);
    
    if (dataSettings.shortUpdateCount >= dataSettings.shortUpdateInterval) {
      var updatedMusicDataShort = [...musicDataShort];
      var updatedNoiseDataShort = [...noiseDataShort];
      updatedMusicDataShort.shift();
      updatedNoiseDataShort.shift();
      updatedMusicDataShort.push(newMusicVolume);
      updatedNoiseDataShort.push(newNoiseVolume);
      setMusicDataShort(updatedMusicDataShort);
      setNoiseDataShort(updatedNoiseDataShort);
      dataSettings.shortUpdateCount = 0;
    } else dataSettings.shortUpdateCount++;
  }

  function toggleWavedOn() {
    setWavedOn(!wavedOn);
    setUserSetNoise(noiseVolume);
  }

  function setVibeHandler(newVibe) {
    if (!wavedOn) return; 
    else if ((-2 <= newVibe) && (newVibe <= 2)){
      setVibe(newVibe);
      wavedSettings.correctingVibe = true;
    }
  }

  function musicOnChange(target) {
    let volume = parseInt(target);
    userActions.holdingMusicSlider = true;
    userActions.holdingMusicSliderValue = target;
  }

  function musicOnMouseUp(target) {
    userActions.holdingMusicSlider = false;
    let volume = parseInt(target);
    setMusicVolume(volume);
    userActions.holdingMusicSliderValue = volume;
    setUserSetMusic(userActions.holdingMusicSliderValue);
  }

  function noiseOnChange(target) {
    let volume = parseInt(target);
    userActions.holdingSlider = true; 
    userActions.holdingSliderValue = volume; 
  }

  function noiseOnMouseUp(target) {
    userActions.holdingSlider = false; 
    let volume = parseInt(target);
    setNoiseVolume(volume);
    userActions.holdingSliderValue = volume; 
    setUserSetNoise(userActions.holdingSliderValue);
  }

  function toggleRandomNoise() {
    setRandomNoise(!randomNoise);
  }

  function setShowModalHandler() {
    setShowModal(!showModal);
  }

  function toggleDemoMode() {
    if (demoMode) {
      setDemoMode(false);
      setWavedOn(true);
      setRandomNoise(true);
      setUserSetNoise(noiseVolume);
    } else {
      setPrepareForDemo(true);
    }
  }

  return (
    <>
      <Helmet>
        <style>{'body { background-color: #0C101C; }'}</style>
      </Helmet>
      <AudioPlayerContainer>
        <DemoButton onClick={toggleDemoMode}>{demoMode?"Back to simulator":"Take UI Tour"}</DemoButton>
        <LinePlot
          musicData = {musicData}
          noiseData = {noiseData}
          xMax = {dataSettings.dataLength}
          yMax = {100}
          legend = {true}
          fadeBox = {true}
        />
        
        {demoMode?
          <AudioPlayerDemo
            setVibe = {setVibeHandler}
            vibe={vibe}
            musicData = {musicDataShort}
            noiseData = {noiseDataShort}
            plotXMax = {dataSettings.dataLengthShort}
            plotYMax = {100}
            wavedOn = {wavedOn}
            musicVolume = {musicVolume}
          />
        :
          <AudioPlayer2
            startVolume = {wavedSettings.startVolume}
            noiseVolume = {noiseVolume}
            musicVolume = {musicVolume}
            genre={genre}
            wavedOn = {wavedOn}
            toggleWavedOn = {toggleWavedOn}
            musicData = {musicDataShort}
            noiseData = {noiseDataShort}
            plotXMax = {dataSettings.dataLengthShort}
            plotYMax = {100}
            setVibe = {setVibeHandler}
            setShowModal={setShowModalHandler}
            vibe = {vibe}
            onNoiseChange={noiseOnChange}
            onNoiseMouseUp={noiseOnMouseUp}
            startNoiseValue={noiseVolume}
            newNoiseValue={userSetNoise}
            onMusicChange={musicOnChange}
            onMusicMouseUp={musicOnMouseUp}
            startMusicValue={musicVolume}
            newMusicValue={musicVolume}
            randomNoise = {randomNoise}
            toggleRandomNoise = {toggleRandomNoise}
            prepareForDemo={prepareForDemo}
            setPrepareForDemo={setPrepareForDemo}
            setDemoMode={setDemoMode}
          />
        }
        <Modal 
          $show={showModal.toString()}
          setShowModal={setShowModalHandler}
          updateGenre={setGenre}
          genre = {genre}
        />
      </AudioPlayerContainer>
    </>
  );
}

export default App;
