import React, { useState } from "react";
import AudioPlayerDemoModal from "./AudioPlayerDemoModal";
import LinePlot2 from "./LinePlot2";
import MuteButton from "./Buttons/MuteButton";
import WavedButton from "./Buttons/WavedButton";
import { LabelContainer, ZoneContainer, ZoneInnerContainer } from "./UI/PanelContainers";
import SourceButton from "./Buttons/SourceButton";
import { ZoneLabel } from "./Text";
import VolumePercentage from "./UI/VolumePercentage";
import VibeSlider from "./VibeSlider";
import { VibeSliderContainer } from "./UI/PanelContainers";
import PeopleButton from "./Buttons/PeopleButton";
import Slider from "./Slider";
import { H4 } from "./Text";

const shadowColor = "grey";
const highlight = {
    zIndex: 10001, 
    boxShadow: `0px 0px 10px ${shadowColor}`,
}
const highlightZ1 = {
    zIndex: 10001, 
    boxShadow: `0px 0px 10px ${shadowColor}`,
    transform: "scale(1.1)"
}
const highlightZ2 = {
    zIndex: 10001, 
    boxShadow: `0px 0px 10px ${shadowColor}`,
    transform: "scale(1.2)"
}

    

const AudioPlayerDemo = (props) => {

    const [demo, setDemo] = useState("NONE");
    const [muteOn, setMuteOn] = useState(true);
    const [wavedOn, setWavedOn] = useState(true);
    const [showSources, setShowSources] = useState(false);

    function sourceOnClick() {
        if (demo==="SOURCE"){
            setShowSources(!showSources);
        }
        setDemo("SOURCE");
    };

    function zoneLabelOnClick() {
        setDemo("ZONE");
    };

    function percentageOnClick() {
        setDemo("PERCENTAGE");
    };

    function plotOnClick() {
        setDemo("PLOT");
    };

    function vibeOnClick() {
        setDemo("VIBE");
    };

    function muteOnClick() {
        setDemo("MUTE");
        if (demo){
            setMuteOn(!muteOn);
        }
    };

    function wavedOnOnClick() {
        setDemo("WAVED");
        if (demo){
            setWavedOn(!wavedOn);
        }
    };

    const moveToFront = {
        position: 'absolute',
        zIndex: 10001,
    }

    function handleModalClick(){
        setDemo(false);
        setWavedOn(true);
        setMuteOn(true);
        setShowSources(false);
    }

    //style="position: absolute; z-index: 3"
    return (
        <>
            <ZoneContainer style={(demo==="ZONE")? highlight:{}}>
                <SourceButton addStyle={(demo==="SOURCE")? highlightZ2:{}} onClick={sourceOnClick}/>
                <ZoneInnerContainer>
                    <LabelContainer><ZoneLabel onClick={zoneLabelOnClick}>Volume Zone</ZoneLabel></LabelContainer>
                    <VolumePercentage addStyle={(demo==="PERCENTAGE")? highlightZ2:{}} onClick={percentageOnClick} volume={props.musicVolume} wavedOn={props.wavedOn}></VolumePercentage>
                    <LinePlot2
                        addStyle={(demo==="PLOT")? highlightZ2:{}}
                        onClick={plotOnClick}
                        musicData = {props.musicData}
                        noiseData = {props.noiseData}
                        xMax = {props.plotXMax}
                        yMax = {props.plotYMax}
                        height = {"50px"}
                        width = {"100vw"}
                        $bgcolor = {"rgb(20, 25, 37)"}
                        legend = {false}
                    />
                    <VibeSliderContainer style={(demo==="VIBE")? highlightZ1:{}} onClick={vibeOnClick} $columnStart={4} $columnEnd={5} $vibe={"true"}>
                        <VibeSlider
                            vibe={props.vibe}
                            setVibe = {props.setVibe}
                        />
                    </VibeSliderContainer>     
                    <MuteButton addStyle={(demo==="MUTE")? highlightZ2:{}} $on={(muteOn).toString()} onClick={muteOnClick}/>
                    <WavedButton addStyle={(demo==="WAVED")? highlightZ2:{}} $on={(wavedOn).toString()} onClick={wavedOnOnClick}/>
                </ZoneInnerContainer>
            </ZoneContainer>
            <br></br>
            <H4>Press something and see what it does!</H4>
            <AudioPlayerDemoModal showSources={showSources} setShowSources={setShowSources} demo={demo} handleModalClick={handleModalClick}></AudioPlayerDemoModal>
        </>
    );
}

export default AudioPlayerDemo;