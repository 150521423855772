import React, { useEffect, useState } from "react";
import { getTrack, loadTracks, playTrack, setVolume, stopTrack, getVolume, tracksLoaded } from "../audio/audioTracks";
import LinePlot2 from "./LinePlot2";
import MuteButton from "./Buttons/MuteButton";
import WavedButton from "./Buttons/WavedButton";
import { LabelContainer, MiniPlotContainer, ZoneContainer, ZoneInnerContainer } from "./UI/PanelContainers";
import SourceButton from "./Buttons/SourceButton";
import { ZoneLabel } from "./Text";
import VolumePercentage from "./UI/VolumePercentage";
import VibeSlider from "./VibeSlider";
import { VibeSliderContainer } from "./UI/PanelContainers";
import PeopleButton from "./Buttons/PeopleButton";
import Slider from "./Slider";


const AudioPlayer = (props) => {

    const [music, setMusic] = useState(null);
    const [musicIsPlaying,  setMusicIsPlaying] = useState(false);

    const [noise, setNoise] = useState(null);
    const [noiseIsPlaying,  setNoiseIsPlaying] = useState(false);

    const [currentGenre, setCurrentGenre] = useState(props.genre);

    useEffect(() => {
        if (props.startVolume) loadTracks(props.startVolume);
        else loadTracks(35);
    }, []);

    useEffect(() => {
        if (tracksReady()) {
            setVolume(props.noiseVolume, noise);
        }
    }, [props.noiseVolume]);

    useEffect(() => {
        if (tracksReady()) {
            setVolume(props.musicVolume, music);
        }
    }, [props.musicVolume]);

    useEffect(() => {
        if (tracksReady() && (props.musicVolume || props.noiseVolume)) {
            updateGenre(props.genre);
        }
    }, [props.genre]);

    useEffect(() => {
        if (props.prepareForDemo) {
            stopMusic();
            stopNoise();
            props.setPrepareForDemo(false);
            props.setDemoMode(true);
        }
    }, [props.prepareForDemo]);

    function tracksReady() {
        var loaded = tracksLoaded();
        if (loaded && noise && music) return true;
        else if (loaded) {
            var n = getTrack('noise');
            var m = getTrack(props.genre);
            setNoise(n);
            setMusic(m);    
        }
        return false;
    }

    function playMusic() {
        if (musicIsPlaying) return;
        setMusicIsPlaying(true);
        const track = getTrack(props.genre);
        setMusic(track);
        playTrack(track);
    }

    function playNoise() {
        if (noiseIsPlaying) return;
        setNoiseIsPlaying(true);
        const track = getTrack('noise');
        setNoise(track);
        playTrack(track);
    }

    function stopMusic() {
        if (!musicIsPlaying) return;
        setMusicIsPlaying(false);
        stopTrack(music);
    }

    function stopNoise() {
        if(!noiseIsPlaying) return;
        setNoiseIsPlaying(false);
        stopTrack(noise);
    }

    function togglePlayMusic(){
        if (musicIsPlaying) stopMusic();
        else playMusic();
    }

    function togglePlayNoise(){
        if (noiseIsPlaying) stopNoise();
        else playNoise();
    }

    function updateGenre(newGenre) {
        if (currentGenre === newGenre) return;
        var oldTrack = getTrack(currentGenre);
        let volume = getVolume(oldTrack);
        const track = getTrack(newGenre);
        setVolume(volume, track);
        setMusic(track);
        setCurrentGenre(newGenre);
        if (musicIsPlaying) {
            stopTrack(oldTrack);
            playTrack(track);
        }
    }

    return(
        <>
            <ZoneContainer>
                <SourceButton onClick={props.setShowModal}/>
                <ZoneInnerContainer>
                    <LabelContainer><ZoneLabel>Volume Zone</ZoneLabel></LabelContainer>
                    <VolumePercentage volume={props.musicVolume} wavedOn={props.wavedOn}></VolumePercentage>
                    {props.wavedOn? <>
                        <LinePlot2
                            musicData = {props.musicData}
                            noiseData = {props.noiseData}
                            xMax = {props.plotXMax}
                            yMax = {props.plotYMax}
                            height = {"50px"}
                            width = {"100vw"}
                            $bgcolor = {"rgb(20, 25, 37)"}
                            legend = {false}
                        />
                        <VibeSliderContainer $columnStart={4} $columnEnd={5} $vibe={"true"}>
                            <VibeSlider
                                vibe={props.vibe}
                                setVibe = {props.setVibe}
                            />
                        </VibeSliderContainer>     
                    </>:
                        <VibeSliderContainer $columnStart={3} $columnEnd={5}>
                            <Slider
                                onChange={props.onMusicChange}
                                onMouseUp={props.onMusicMouseUp}
                                startValue={props.startMusicVolume}
                                newValue={props.newMusicVolume}
                                $primaryColor={"true"}
                            />
                        </VibeSliderContainer>
                    }
                    
                    <MuteButton $on={(musicIsPlaying===true).toString()} onClick={togglePlayMusic}/>
                    <WavedButton $on={(props.wavedOn).toString()} onClick={props.toggleWavedOn}/>
                </ZoneInnerContainer>
            </ZoneContainer>
            <ZoneContainer>
                <SourceButton onClick={() => {}} stale/>
                <ZoneInnerContainer>
                    <LabelContainer><ZoneLabel>Simulate Crowd</ZoneLabel></LabelContainer>
                    <VolumePercentage volume={Math.round(props.noiseVolume/10)*10} wavedOn={props.wavedOn}></VolumePercentage>
                    <VibeSliderContainer $columnStart={3} $columnEnd={5}>
                        <Slider
                            onChange={props.onNoiseChange}
                            onMouseUp={props.onNoiseMouseUp}
                            startValue={props.startNoiseVolume}
                            newValue={props.newNoiseVolume}
                        />
                    </VibeSliderContainer>
                    <MuteButton $on={(noiseIsPlaying===true).toString()} onClick={togglePlayNoise}/>
                    <PeopleButton $togglable={true} $on={(props.randomNoise===true).toString()} onClick={props.toggleRandomNoise}/>
                </ZoneInnerContainer>
            </ZoneContainer>
        </>
    );
}


export default AudioPlayer;
