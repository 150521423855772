import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {ReactComponent as Svg} from '../icons/Handle.svg';
import { VibeSliderContainer, appear } from './UI/PanelContainers';
import {ReactComponent as AddSvg} from '../icons/SlimAdd.svg';
import {ReactComponent as SubSvg} from '../icons/Subtract.svg';
import useWindowDimensions from '../utils/windowUtils';


const HandleIcon = styled(Svg)`
    position: absolute;
    left: ${props => props.position? `calc(${props.position}% + ${props.pixels}px)`: 50};
    pointer-events: none;
    height: 0.95rem;
    width: 0.95rem;
    circle {
        fill: black;
        fill-opacity: 0.4;
    }; 
    rect {
      fill-opacity: 0;
    }
`

const AddIcon = styled(AddSvg)`
    position: absolute;
    left: ${props => props.position? props.position: 0}%;
    pointer-events: none;
    height: 1rem;
    width: 1rem;
    rect {
        fill: grey;
    };
`
const SubIcon = styled(SubSvg)`
    position: absolute;
    left: ${props => props.position? props.position: 0}%;
    pointer-events: none;
    height: 1rem;
    width: 1rem;
    rect {
        fill: grey;
    };
`

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 50px;
    background-color: #0C101C; 
    animation: ${appear} 1s;  
    animation-fill-mode: forwards;
    @media (max-width: 770px){
      height: 40px; 
    }
`

//#A8DC00 Waved green
const StyledSlider = styled.input`
-webkit-appearance: none;
appearance: none;
display: block;
appearance: none;
width: 100%;
cursor: pointer;
margin: 0px;

&::-webkit-slider-runnable-track {
  width: 100%;
  height:50px;
  background: #141925; 
  @media (max-width: 770px){
    height: 40px; 
  }
}

&::-webkit-slider-thumb {
  position: relative;
  appearance: none;
  height: 50px;
  width: 50px;
  background-size: cover;
  border-radius: 2px;
  border: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 150ms;
  background-color: ${props => props.$primaryColor=="true"? "#A8DC00": "#e53e5e"};
  @media (max-width: 770px){
    height: 40px; 
    width: 40px;
  }
}
`
//
const Slider = (props) => {
  const [sliderValue, setSliderValue] = useState(props.startValue);
  const [handlePosition, setHandlePosition] = useState(props.startValue);
  const [pixels, setPixels] = useState(20);
  const {width, height} = useWindowDimensions();

  useEffect(() => {
    setSliderValue(props.newValue);
  },[props.newValue]);

  function updateHandlePosition(value){
    setHandlePosition(value);
    var min; 
    var max;
    if (width < 770) {
      var min = 13;
      var max = -27;
    } else {
      var min = 18;
      var max = -32;
    }
    var pix = value*(max-min)/(100) + min;
    setPixels(pix);
  }
  
  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
    props.onChange(event.target.value);
    updateHandlePosition(event.target.value)
  };

  const handleMouseUp = (event) => {
    props.onMouseUp(event.target.value);
    updateHandlePosition(event.target.value)
  }

  return (
      <Container>
      <StyledSlider
        type="range"
        min="0"
        max="100"
        value={sliderValue}
        onChange={handleSliderChange}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleMouseUp}
        $primaryColor = {props.$primaryColor}
      />
      <HandleIcon position={handlePosition} pixels={pixels}></HandleIcon>
      <SubIcon position={2} ></SubIcon>
      <AddIcon position={94} ></AddIcon>
    </Container>
  );
};

export default Slider;