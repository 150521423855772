
import styled from "styled-components"

const Overlay = styled.div`
    position: fixed;
    display: ${ props => props.$show===("true")? "flex" : "none"};
    justify-content: ${ props => props.$show===("true")? "center" : "none"};
    align-items: ${ props => props.$show===("true")? "center" : "none"};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(100, 100, 100, 0.5); 
    background-color: ${props => props.color? props.color : "rgba(100, 100, 100, 0.5)"};
    z-index: 10000; 
`

export default Overlay;