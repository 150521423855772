import React, { useEffect, useState } from "react";
import styled from "styled-components";
import GenreContainer from "../containers/GenreContainer";
import { H1, H2, H3 } from "./Text";

const StyledText = styled.span`
    color: ${props => (props.$selected=="true")? "black" : "#B3B7C7"};
    font-family: KareliaWeb-Medium, Montserrat, sans-serrif;
    font-size: 12px;
`

const RowContainer = styled.div`
    display: grid; 
    grid-template-columns: 1fr 1fr;
    justify-items: center; 
    align-items: center; 
    gap: 5px;
    margin-bottom: 5px;
    width: 100%;
    background-color: #0C101C;
`
const GenreButton = styled.button`
    display: flex; 
    flex-direction: column; 
    justify-content: flex-start;
    background-color: ${props => props.$primary=="true"? "#A8DC00" : "#222731"};
    color: ${props => props.$primary=="true"? "black" : "white"};;
    width: 100%;
    padding: 10px;
    border: none;
    height: 100%;
    margin: 2px;
    font-size: 16px;
    border-radius: 2px;
    font-family: KareliaWeb-Bold, Montserrat, sans-serif;
`

const GenreSelector = (props) => {

    function updateGenre(event, genre){
        props.updateGenre(event, genre)
    }

    return (
        <GenreContainer>
            <H2>Select Source</H2>
            <RowContainer>
                <GenreButton $primary={(props.genre==='lounge').toString()} onClick={(event) => {updateGenre(event, 'lounge')}}>
                   Player - Lounge
                    <StyledText $selected={(props.genre==='lounge').toString()}>Automated</StyledText>
                </GenreButton>
                <GenreButton $primary={(props.genre==='upbeat').toString()} onClick={(event) => {updateGenre(event, 'upbeat')}}>
                    DJ - Upbeat
                    <StyledText $selected={(props.genre==='upbeat').toString()}>Automated</StyledText>
                </GenreButton>
            </RowContainer>
            <RowContainer>
                <GenreButton $primary={(props.genre==='pop').toString()} onClick={(event) => {updateGenre(event, 'pop')}}>
                    AUX - Pop
                    <StyledText $selected={(props.genre==='pop').toString()}>Automated</StyledText>
                </GenreButton>
                <GenreButton $primary={(props.genre==='classical').toString()} onClick={(event) => {updateGenre(event, 'classical')}}>
                    iPad - Classical
                    <StyledText $selected={(props.genre==='classical').toString()}>Automated</StyledText>
                </GenreButton>
            </RowContainer>
        </GenreContainer>
    )
}

export default GenreSelector