import styled from "styled-components";

const IconButtonContainer = styled.button`
    grid-column-start: ${props => props.$columnStart? props.$columnStart : 1};
    grid-column-end: ${props => props.$columnStart? (props.$columnStart+1) : 2};
    width: 50px;
    height: 50px;
    background-color: rgb(20, 25, 37);
    border: none;
    
`

export default IconButtonContainer;